import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/pro-duotone-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
import { getVulnerabilitySummary } from '../../graphql/queries';
import Card from './Card';
import DonutChart from '../charts/DonutChart';

type Props = {
  customerId: string;
  viewDetailsCallback?: (...args: any[]) => any;
};

const ScannedResourcesCard = ({ customerId, viewDetailsCallback }: Props) => {
  const useMountEffect = (func: any) => useEffect(func, []);
  const [instanceSeverityCounts, setInstanceSeverityCounts] = useState();
  const [loadingSeverityCounts, setLoadingSeverityCounts] = useState(true);
  const [vulnerabilitiesCountsError, setVulnerabilitiesCountsError] = useState('');
  let scannedInstanceCount: any;
  let unscannedInstanceCount: any;

  const loadInitialData = async () => {
    try {
      const vulerabilitySummaryCallParams = { params: { customerId } };
      const vulnerabilitiesSummaryData = await API.graphql(
        graphqlOperation(getVulnerabilitySummary, vulerabilitySummaryCallParams)
      );

      const totalCountResult = (vulnerabilitiesSummaryData as any).data.getVulnerabilitySummary.totalInstanceCount;

      scannedInstanceCount = (vulnerabilitiesSummaryData as any).data.getVulnerabilitySummary
        .instanceVulnerabilitySeverityCounts;
      unscannedInstanceCount = totalCountResult - scannedInstanceCount;

      initVulnerabilityCounts((vulnerabilitiesSummaryData as any)?.errors);
    } catch (error) {
      console.error('Error initializing security dashboard: ', error);

      // Throw and let error boundry deal with it
      throw error;
    }
  };

  useMountEffect(() => {
    loadInitialData();
  });

  const initVulnerabilityCounts = (errors: any) => {
    let totalInstanceCount;

    try {
      const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      const chart = {
        height: viewWidth >= 2560 ? 350 : 250,
      };

      const setInstanceSeverityCountsOptions = { chart };

      unscannedInstanceCount = unscannedInstanceCount < 0 ? 0 : unscannedInstanceCount;
      scannedInstanceCount = scannedInstanceCount < 0 ? 0 : scannedInstanceCount;

      if (unscannedInstanceCount > 0 || scannedInstanceCount > 0) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        totalInstanceCount = scannedInstanceCount + unscannedInstanceCount;

        (setInstanceSeverityCountsOptions as any).title = { text: `${totalInstanceCount} Resources` };

        (setInstanceSeverityCountsOptions as any).series = [
          {
            name: '',
            data: [
              { name: `Resources Scanned`, y: scannedInstanceCount, color: '#4CA97A' },
              { name: `Resources Not <br> Scanned`, y: unscannedInstanceCount, color: '#FFBF00' },
            ],
          },
        ];
      } else {
        (setInstanceSeverityCountsOptions as any).series = [];
      }

      setInstanceSeverityCounts({
        ...setInstanceSeverityCountsOptions,
        // @ts-expect-error TS(2345): Argument of type '{ cSVFileName: string; errorLoad... Remove this comment to see the full error message
        cSVFileName: 'Scanned Resources.csv',
        errorLoadingData: !!errors,
      });
    } catch (error) {
      console.error('Error initializing vulnerability counts: ', error);

      setVulnerabilitiesCountsError('Error Loading Data');
    }

    setLoadingSeverityCounts(false);
  };

  return (
    <Card
      icon={<FontAwesomeIcon icon={faShieldAlt} fixedWidth />}
      title="Total Resources"
      classNames="w-full"
      viewDetailsCallback={viewDetailsCallback}
      loading={loadingSeverityCounts}
      errorMessage={vulnerabilitiesCountsError}
      component={<DonutChart chartOptions={instanceSeverityCounts} />}
    />
  );
};

export default ScannedResourcesCard;
