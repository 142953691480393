/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMonitoringFilters = /* GraphQL */ `
  query GetMonitoringFilters(
    $params: QueryGetMonitoringFiltersParamsInput!
    $query: QueryGetMonitoringFiltersQueryInput!
  ) {
    getMonitoringFilters(params: $params, query: $query) {
      items {
        workloads {
          id
          environments {
            id
            services
          }
          regions {
            id
            services
          }
        }
        regions {
          id
          workloads {
            id
            services
          }
          environments {
            id
            services
          }
        }
        environments {
          id
          workloads {
            id
            services
          }
          regions {
            id
            services
          }
        }
        services {
          id
          name
          metrics {
            ids
            name
          }
        }
      }
    }
  }
`;
export const getMonitoringMetrics = /* GraphQL */ `
  query GetMonitoringMetrics(
    $params: QueryGetMonitoringMetricsParamsInput!
    $query: QueryGetMonitoringMetricsQueryInput!
  ) {
    getMonitoringMetrics(params: $params, query: $query) {
      items {
        metricname
        workload
        arn
        id
        type
        state
        otherMetrics
        metrics {
          value
          timestamp
        }
        tags
        size
      }
    }
  }
`;
export const listAllTickets = /* GraphQL */ `
query ListAllTickets(
  $query: QueryQueryInput
) {
  listAllTickets(query: $query) {
    items {
      customerId
      id
      type
      number
      category
      subject
      customer
      state
      openedBy
      openedByEmail
      openedAt
      createdOn
      updatedOn
      startDate
      data
      attachments
      changeRequest {
        id
        number
      }
      assignedTo
      assignedToName
      linkedIncidents {
        id
        number
      }
    }
    token
  }
}
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($params: QueryGetTicketParamsInput!) {
    getTicket(params: $params) {
      customerId
      id
      type
      number
      category
      subject
      customer
      state
      openedBy
      openedByEmail
      openedAt
      createdOn
      updatedOn
      startDate
      data
      attachments
      changeRequest {
        id
        number
      }
      incident {
        id
        number
      }
      assignedTo
      assignedToName
      linkedIncidents {
        id
        number
      }
      linkedSupportRequest {
        id
        number
      }
    }
  }
`;
export const getTicketComments = /* GraphQL */ `
  query GetTicketComments(
    $params: QueryGetTicketCommentsParamsInput!
    $query: QueryGetTicketCommentsQueryInput
  ) {
    getTicketComments(params: $params, query: $query) {
      items {
        id
        ticketId
        comment
        createdOn
        createdBy
      }
      attachments {
        id
        key
        fileName
        hash
      }
      token
    }
  }
`;
export const getTicketAttachment = /* GraphQL */ `
  query GetTicketAttachment($params: QueryGetTicketAttachmentParamsInput!) {
    getTicketAttachment(params: $params) {
      id
      fileName
      downloadUrl
    }
  }
`;

export const getMonitorStatusSite24x7  = /* GraphQL */ `
  query GetMonitorStatusSite24x7($params: QueryGetMonitorStatusSite24x7ParamsInput!) {
    getMonitorStatusSite24x7(params: $params){
      downValue
      upValue
      troubleValue
      criticalValue
      data
    }
  }
`;
export const getMonitorDetailsSite24x7 = /* GraphQL */ `
query GetMonitorDetailsSite24x7(
    $params: QueryGetMonitorDetailsSite24x7ParamsInput!
    $query: QueryGetMonitorDetailsSite24x7QueryInput!
  ) {
  getMonitorDetailsSite24x7(params: $params, query: $query){
    availabilitySummary
    logReportByDate
  }
}
`;
export const getVulnerabilitySummary = /* GraphQL */ `
  query GetVulnerabilitySummary(
    $params: QueryGetVulnerabilitySummaryParamsInput!
  ) {
    getVulnerabilitySummary(params: $params) {
      vulnerabilitySeverityCounts {
        Severity1
        Severity2
        Severity3
        Severity4
        Severity5
      }
      totalResources {
        id
        ip
        lastScanDateTime
        instanceId
        tags {
          Key
          Value
        }
        severities {
          Severity1
          Severity2
          Severity3
          Severity4
          Severity5
        }
      }
      topVulnerabilities {
        qid
        name
        firstDetected
        severity
        count
      }
      instanceVulnerabilitySeverityCounts
      clearInstanceCount
      totalInstanceCount
    }
  }
`;
export const getVulnerabilityHost = /* GraphQL */ `
  query GetVulnerabilityHost(
    $params: QueryGetVulnerabilityHostParamsInput!
    $query: QueryGetVulnerabilityHostQueryInput
  ) {
    getVulnerabilityHost(params: $params, query: $query) {
      id
      asset_id
      ip
      ipv6
      instanceId
      region
      tracking_method
      network_id
      os
      os_cpe
      dns
      cloud_provider
      cloud_service
      cloud_resource_id
      ec2_instance_id
      netbios
      qg_hostid
      last_scan_datetime
      last_vm_scanned_date
      last_vm_auth_scanned_date
      last_vm_auth_scanned_duration
      last_pc_scanned_date
      tags {
        tag_id
        name
        color
        background_color
      }
      metadata {
        ec2 {
          name
          last_status
          value
          last_success_date
          last_error_date
          last_error
        }
        google {
          name
          last_status
          value
          last_success_date
          last_error_date
          last_error
        }
        azure {
          name
          last_status
          value
          last_success_date
          last_error_date
          last_error
        }
      }
      cloud_provider_tags {
        name
        value
        last_success_date
      }
      token
      detections {
        qid
        name
        type
        severity
        port
        protocol
        gqdn
        ssl
        instance
        results
        status
        first_found_datetime
        last_found_datetime
        times_found
        last_test_datetime
        last_update_datetime
        last_fixed_datetime
        first_reopened_datetime
        last_reopened_datetime
        times_reopened
        service
        is_ignored
        is_disabled
        affect_running_kernel
        affect_running_service
        affect_exploitable_config
        last_processed_datetime
      }
    }
  }
`;
export const getVulnerabilityKnowledgebase = /* GraphQL */ `
  query GetVulnerabilityKnowledgebase(
    $params: QueryGetVulnerabilityKnowledgebaseParamsInput!
  ) {
    getVulnerabilityKnowledgebase(params: $params) {
      qid
      vuln_type
      severity_level
      title
      category
      detection_info
      last_customization
      last_service_modification_datetime
      published_datetime
      bugtraq_list {
        id
        url
      }
      patchable
      software_list {
        product
        vendor
      }
      vendor_reference_list {
        id
        url
      }
      cve_list {
        id
        url
      }
      diagnosis
      diagnosis_comment
      consequence
      consequence_comment
      solution
      solution_comment
      compliance_list {
        type
        section
        description
      }
      correlation_exploits {
        src_name
        explt_list {
          ref
          desc
          link
        }
      }
      correlation_malware {
        src_name
        mw_list {
          mw_id
          mw_type
          mw_platform
          mw_alias
          mw_rafting
          mw_link
        }
      }
      cvvs {
        _source
        base {
          _source
          content
        }
        temporal
        vector_string
        access {
          vector
          complexity
        }
        impact {
          confidentiality
          integrity
          availability
        }
        authentication
        exploitability
        redemption_level
        report_confidence
      }
      cvvs_v3 {
        _source
        base {
          _source
          content
        }
        temporal
        vector_string
        attack {
          vector
          complexity
        }
        impact {
          confidentiality
          integrity
          availability
        }
        privileges_required
        user_interaction
        scope
        exploit_code_maturity
        redemption_level
        report_confidence
      }
      pci_flag
      automatic_pci_fail
      pci_reasons
      threat_intelligence {
        _id
        content
      }
      supported_modules
      discovery {
        remote
        auth_type_list
        additional_info
      }
      is_disabled
      change_log_list {
        change_date
        comments
      }
    }
  }
`;
export const getCosts = /* GraphQL */ `
  query GetCosts(
    $params: QueryGetCostsParamsInput!
    $query: QueryGetCostsQueryInput!
  ) {
    getCosts(params: $params, query: $query) {
      items {
        accountId
        region
        category
        resource
        date
        cost
      }
      token
    }
  }
`;
export const getCostsFilter = /* GraphQL */ `
  query GetCostsFilter(
    $params: QueryGetCostsFilterParamsInput!
    $query: QueryGetCostsFilterQueryInput!
  ) {
    getCostsFilter(params: $params, query: $query) {
      accounts {
        id
        regions
        resources
      }
      regions {
        accounts
        id
        resources
      }
      resources {
        accounts
        id
        regions
      }
    }
  }
`;
export const getCostsSummary = /* GraphQL */ `
  query GetCostsSummary(
    $params: QueryGetCostsSummaryParamsInput!
    $query: QueryGetCostsSummaryQueryInput!
  ) {
    getCostsSummary(params: $params, query: $query) {
      items {
        yearmonth
        category
        resource
        cost
      }
    }
  }
`;
export const listEmpathUsers = /* GraphQL */ `
  query ListEmpathUsers(
    $query: QueryListEmpathUsersQueryInput!
  ) {
    listEmpathUsers(query: $query) {
      id
      email
      sub
      customerId
      customerIds
      serviceNowId
      groups
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      onboarded
      canApproveChangeRequest
    }
  }
`;
export const getEmpathUser = /* GraphQL */ `
  query GetEmpathUser($params: QueryGetEmpathUserParamsInput!) {
    getEmpathUser(params: $params) {
      id
      email
      sub
      customerId
      customerIds
      serviceNowId
      groups
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      onboarded
      canApproveChangeRequest
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const listInventorys = /* GraphQL */ `
  query ListInventorys(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byAccount = /* GraphQL */ `
  query ByAccount(
    $account: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccount(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byService = /* GraphQL */ `
  query ByService(
    $service: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byService(
      service: $service
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byResource = /* GraphQL */ `
  query ByResource(
    $resource: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byResource(
      resource: $resource
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byAccountByRegion = /* GraphQL */ `
  query ByAccountByRegion(
    $account: String
    $region: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccountByRegion(
      account: $account
      region: $region
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerIdByWorkload = /* GraphQL */ `
  query ByCustomerIdByWorkload(
    $customerId: String
    $workload: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerIdByWorkload(
      customerId: $customerId
      workload: $workload
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerId = /* GraphQL */ `
  query ByCustomerId(
    $customerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerIdByService = /* GraphQL */ `
  query ByCustomerIdByService(
    $customerId: String
    $service: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerIdByService(
      customerId: $customerId
      service: $service
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      products 
      phone
      costUpdatedDate
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
      openTicketCount
      featureFlags {
        supportOnly
        resaleOnly
        mcm
        vmc
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        products
        phone
        costUpdatedDate
        createdAt
        updatedAt
        isActive
        identityProvider
        metadataUrl
        ssoUrn
        ssoUrl
        openTicketCount
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
      canApproveChangeRequest
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
      }
      nextToken
    }
  }
`;
export const userBySub = /* GraphQL */ `
  query UserBySub(
    $sub: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
        dashboardSettings
      }
      nextToken
    }
  }
`;
export const usersByCustomerId = /* GraphQL */ `
  query UsersByCustomerId(
    $customerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
      }
      nextToken
    }
  }
`;

export const getMyTeam = /* GraphQL */ `
  query GetMyTeam($customerId: String!) {
    getMyTeam(customerId: $customerId) {
      myTeam {
        teamMemberId
        fullName
        email
        role
        isPrimary
        phoneNumber
        profilePhotoUrl
      }
      createdAt
      customerId
      updatedAt
    }
  }
`;

export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $sub: String
    $id: ModelIDKeyConditionInput
    $filter: NotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      sub: $sub
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sub
        id
        message
        when
        isRead
        customerId
        type
        key
        createdAt
      }
      nextToken
    }
  }
`;

export const getSiteSettings = /* GraphQL */ `
  query GetSiteSettings($serviceName: ID!) {
    getSiteSettings(serviceName: $serviceName) {
      serviceName
      isUnderMaintenance
    }
  }
`;

export const generateCSVReport = /* GraphQL */ `
  query GenerateCSVReport(
    $body: QueryGenerateCSVReportBodyInput!
    ) {
    generateCSVReport(body: $body
      ) {
        statusCode
        payload 
      }
  }
`;
