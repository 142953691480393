/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateInventory = /* GraphQL */ `
  subscription OnCreateInventory {
    onCreateInventory {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInventory = /* GraphQL */ `
  subscription OnUpdateInventory {
    onUpdateInventory {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInventory = /* GraphQL */ `
  subscription OnDeleteInventory {
    onDeleteInventory {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      name
      type
      costUpdatedDate
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      name
      type
      costUpdatedDate
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      name
      type
      costUpdatedDate
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($sub: String!) {
    onCreateNotification(sub: $sub) {
      sub
      id
      message
      when
      isRead
      customerId
      type
      key
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($sub: String!) {
    onUpdateNotification(sub: $sub) {
      sub
      id
      message
      when
      isRead
      customerId
      type
      key
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($sub: String!) {
    onDeleteNotification(sub: $sub) {
      sub
      id
      message
      when
      isRead
      customerId
      type
      key
      createdAt
      updatedAt
    }
  }
`;
