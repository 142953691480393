import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/pro-duotone-svg-icons';
import { getVulnerabilityHost, getVulnerabilityKnowledgebase } from '../../../graphql/queries';
import PageHeader from '../../../components/header/PageHeader';
import { EmpathTable } from '../../../components/tables';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import VulnerabilityDetailsModal from '../../../components/modal/VulnerabilityDetailsModal';

const InstanceVulnerabilitiesList = () => {
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId, hostId } = useParams();

  const [host, setHost] = useState();
  const [vulnerability, setVulnerability] = useState();

  const columns = [
    {
      header: 'Title',
      accessor: 'name',
      colSpan: 3,
      cell: function ViewDetailsCell(cellInfo: any) {
        return (
          <button
            type="button"
            onClick={async () => {
              try {
                const response = await API.graphql(
                  graphqlOperation(getVulnerabilityKnowledgebase, { params: { qid: cellInfo.row.original.qid } })
                );

                setVulnerability((response as any).data.getVulnerabilityKnowledgebase);
              } catch (e) {
                console.error(e);
              }
            }}
            className="text-center text-indigo-600 hover:text-indigo-900"
          >
            {cellInfo.value}
          </button>
        );
      },
    },
    { header: 'Date Detected', accessor: 'first_found_datetime' },
    {
      header: 'Severity Level',
      accessor: 'severity',
      cell: function StatusCell(cellInfo: any) {
        return (
          <div className="flex w-6 h-6 text-center bg-red-600 rounded-full">
            <div className="m-auto text-white">{cellInfo.value}</div>
          </div>
        );
      },
    },
  ];

  const loadData = useCallback(
    async (options: any) => {
      const response = await API.graphql(
        graphqlOperation(getVulnerabilityHost, {
          params: { customerId, hostId },
          query: {
            query: btoa(JSON.stringify(options)),
          },
        })
      );

      setHost((response as any)?.data?.getVulnerabilityHost);

      return {
        items: (response as any)?.data?.getVulnerabilityHost?.detections ?? [],
        token: (response as any)?.data?.getVulnerabilityHost?.token,
      };
    },
    [customerId, hostId]
  );

  return (
    <div className="mt-8">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="m-1">
          <PageHeader title="Security" />
          <Breadcrumbs
            crumbs={[
              {
                text: 'Resources',
                path: `/customers/${customerId}/security`,
              },
              {
                text: 'Resources Details',
              },
            ]}
          />
        </div>
        {vulnerability && (
          <VulnerabilityDetailsModal
            doShow={vulnerability !== undefined}
            cancel={() => setVulnerability(undefined)}
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            region={host.region}
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            instanceId={host.instanceId}
            vulnerability={vulnerability}
          />
        )}
        <div className="pt-2">
          <EmpathTable
            title={`${(host as any)?.instanceId ?? ''} Vulnerabilities`}
            icon={<FontAwesomeIcon icon={faShieldAlt} fixedWidth />}
            columns={columns}
            sort={[{ field: 'first_found_datetime', sort: 'desc' }]}
            loadData={loadData}
            viewPortHeight={40}
          />
        </div>
      </div>
    </div>
  );
};

export default InstanceVulnerabilitiesList;
