/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import noData from 'highcharts/modules/no-data-to-display';

Exporting(Highcharts);
noData(Highcharts);

export const dataFormat = {
  money: (value: any) =>
    value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
};

Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
  const path = [
    // Arrow stem
    'M',
    x + w * 0.5,
    y,
    'L',
    x + w * 0.5,
    y + h * 0.7,
    // Arrow head
    'M',
    x + w * 0.3,
    y + h * 0.5,
    'L',
    x + w * 0.5,
    y + h * 0.7,
    'L',
    x + w * 0.7,
    y + h * 0.5,
    // Box
    'M',
    x,
    y + h * 0.9,
    'L',
    x,
    y + h,
    'L',
    x + w,
    y + h,
    'L',
    x + w,
    y + h * 0.9,
  ];

  return path;
};

type Props = {
  chartOptions?: {
    title?: {
      text: string;
      style?: {
        fontSize?: string;
        margin?: number;
      };
      align?: string;
    };
    chart?: {
      type?: string;
      height?: number;
      marginBottom?: number;
    };
    xAxis?: {
      categories: string[];
    };
    yAxis?: {
      title?: {
        text?: string;
      };
      labels?: {
        formatter?: (...args: any[]) => any;
      };
      max?: number;
    };
    tooltip?: any;
    legend?: {
      enabled?: boolean;
      align?: string;
      verticalAlign?: string;
      layout?: string;
      x?: number;
      y?: number;
    };
    plotOptions?: {
      pie?: {
        dataLabels?: {
          enabled?: boolean;
        };
      };
    };
    series?: {
      name: string;
      data: number[];
    }[];
    cSVFileName: string;
    errorLoadingData?: boolean;
  };
};

const BarChart = ({ chartOptions }: Props) => {
  // @ts-expect-error TS(2339): Property 'errorLoadingData' does not exist on type... Remove this comment to see the full error message
  const { errorLoadingData } = chartOptions;

  const options = {
    boost: {
      enabled: false, // boost on bar charts can cause the column width to shrink down to like 5 px
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      height: chartOptions.chart?.height ?? 250,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      marginBottom: chartOptions.chart?.marginBottom ?? 60,
    },
    title: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      text: chartOptions.title?.text ?? '',
      style: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        fontSize: chartOptions.title?.style?.fontSize ?? '14px',
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        margin: chartOptions.title?.style?.margin ?? 50,
        fontFamily: 'Muli',
      },
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      align: chartOptions.title?.align ?? 'left',
    },
    xAxis: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      categories: chartOptions.xAxis.categories,
    },
    yAxis: {
      title: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        text: chartOptions.yAxis?.title?.text ?? '',
      },
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      max: chartOptions.yAxis?.max ?? null,
    },
    tooltip: {
      // @ts-expect-error TS(7023): 'formatter' implicitly has return type 'any' becau... Remove this comment to see the full error message
      formatter() {
        // @ts-expect-error TS(2339): Property 'point' does not exist on type '{ formatt... Remove this comment to see the full error message
        const { point } = this;

        return `${point.series.name}: <b>${
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          chartOptions.tooltip?.formatter ? chartOptions.tooltip.formatter(point.y) : point.y
        }</b>`;
      },
    },
    legend: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      enabled: chartOptions.legend?.enabled || false,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      align: chartOptions.legend?.align ?? 'right',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      verticalAlign: chartOptions.legend?.verticalAlign ?? 'top',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      layout: chartOptions.legend?.layout ?? 'vertical',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      x: chartOptions.legend?.x ?? 10,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      y: chartOptions.legend?.y ?? 0,
    },
    exporting: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      filename: chartOptions.cSVFileName.substring(0, chartOptions.cSVFileName.length - 4),
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              // @ts-expect-error TS(7023): 'formatter' implicitly has return type 'any' becau... Remove this comment to see the full error message
              formatter() {
                // @ts-expect-error TS(2339): Property 'point' does not exist on type '{ enabled... Remove this comment to see the full error message
                const { point } = this;

                return `${point.series.name}: <b>${
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  chartOptions.tooltip?.formatter ? chartOptions.tooltip.formatter(point.y) : point.y
                }</b>`;
              },
            },
          },
        },
      },
      buttons: {
        contextButton: {
          symbol: 'download',
          symbolFill: '#463490',
          symbolStroke: '#463490',
          menuItems: [
            'downloadJPEG',
            'downloadPDF',
            {
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              text: Highcharts.getOptions().lang.downloadCSV || 'Download CSV',
              onclick() {
                const json: any = [];

                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                chartOptions.series.forEach(val => {
                  const value = val;
                  for (let index = 0; index < value.data.length; index += 1) {
                    const chartData = value.data[index];
                    const xValue = (value as any).xValue[index];
                    // @ts-expect-error TS(7006): Parameter 'x' implicitly has an 'any' type.
                    const isExist = json.filter(x => x.Category === xValue);
                    if (isExist.length > 0) {
                      for (let i = 0; i < json.length; i += 1) {
                        if (json[i].Category === xValue) {
                          json[i][value.name] = chartData;
                          break;
                        }
                      }
                    } else {
                      const metricData = {
                        Category: xValue,
                        [value.name]: chartData,
                      };
                      json.push(metricData);
                    }
                  }
                });

                let csv = '';

                if (json.length > 0) {
                  const fields = Object.keys(json[0]);
                  const replacer = function (key: any, value: any) {
                    return value === null ? '' : value;
                  };

                  // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
                  csv = json.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

                  (csv as any).unshift(fields.join(',')); // add header column
                  csv = (csv as any).join('\r\n');
                }

                const element = document.createElement('a');
                const file = new Blob([csv], { type: 'text/plain' });

                element.href = URL.createObjectURL(file);
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                element.download = chartOptions.cSVFileName;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              },
            },
          ],
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    series: chartOptions.series,
    lang: {
      noData: `${errorLoadingData ? 'Error Loading Data' : 'No Data Found'}`,
    },
    noData: {
      style: {
        fontFamily: 'Karla, sans-serif',
        fontWeight: 'normal',
        fontSize: '1rem',
        color: `${errorLoadingData ? '#D00505' : '#696279'}`,
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BarChart;
