import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/pro-duotone-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

import { getCostsSummary } from '../../graphql/queries';
import { shiftToUtcEndOfMonth, shiftToStartOfUtcDay } from '../../shared/dateHelpers';

import Card from './Card';
import BarChart, { dataFormat } from '../charts/BarChart';

type Props = {
  customerId: string;
  detailLink?: (...args: any[]) => any;
  classNames?: string;
};

const EC2MonthlyCostsCard = ({ customerId, detailLink, classNames }: Props) => {
  const [eC2CostChartOptions, setEC2CostChartOptions] = useState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endDate = DateTime.local().minus({ month: 1 }).startOf('month').toJSDate();
        const startDate = DateTime.fromJSDate(endDate).minus({ month: 3 }).toJSDate();
        const result = await API.graphql(
          graphqlOperation(getCostsSummary, {
            params: { customerId },
            query: {
              startDate: shiftToStartOfUtcDay(startDate),
              endDate: shiftToUtcEndOfMonth(endDate),
              resource: 'EC2',
              region: null,
              accountId: null,
            },
          })
        );
        const ec2CostDataSummary = (result as any)?.data?.getCostsSummary?.items ?? [];
        const eC2CostSummaryGraphData = ec2CostDataSummary.map((cost: any) => {
          const month = new Date(`${cost.yearmonth}T00:00:00`);
          cost.month = {
            monthOrder: month.getTime(),
            monthName: month.toLocaleString('default', { month: 'long' }),
          };
          return cost;
        });
        const ec2SCostSummarySeries = ec2CostDataSummary.length
          ? [
              {
                name: 'EC2',
                xValue: [
                  ...new Set(
                    eC2CostSummaryGraphData
                      .map((costItem: any) => costItem.month)
                      .sort((a: any, b: any) => a.monthOrder - b.monthOrder)
                      .map((costItem: any) => costItem.monthName)
                  ),
                ],
                data: [
                  ...eC2CostSummaryGraphData
                    .sort((a: any, b: any) => a.month.monthOrder - b.month.monthOrder)
                    .map((costItem: any) => costItem.cost),
                ],
              },
            ]
          : [];
        const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        setEC2CostChartOptions({
          // @ts-expect-error TS(2345): Argument of type '{ xAxis: { categories: unknown[]... Remove this comment to see the full error message
          xAxis: {
            categories: [
              ...new Set(
                eC2CostSummaryGraphData
                  .map((costItem: any) => costItem.month)
                  .sort((a: any, b: any) => a.monthOrder - b.monthOrder)
                  .map((costItem: any) => costItem.monthName)
              ),
            ],
          },
          chart: {
            height: viewWidth >= 2560 ? 350 : 250,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            formatter: dataFormat.money,
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            },
          },
          series: ec2SCostSummarySeries,
          cSVFileName: 'EC2 Cost Data.csv',
          errorLoadingData: !!(result as any).errors,
        });
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        console.error('Error loading EC2 metric data: ', error);
        setError('Error Loading Data');
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return (
    <Card
      icon={<FontAwesomeIcon icon={faDollarSign} className="text-lg" fixedWidth />}
      title="EC2 Monthly Costs"
      // @ts-expect-error TS(2322): Type '((...args: any[]) => any) | null' is not ass... Remove this comment to see the full error message
      viewDetailsCallback={detailLink || null}
      classNames={classNames}
      errorMessage={error}
      loading={loading}
      component={<BarChart chartOptions={eC2CostChartOptions} />}
    />
  );
};

export default EC2MonthlyCostsCard;
