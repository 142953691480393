import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/pro-duotone-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

import { getCostsSummary } from '../../graphql/queries';

import Card from './Card';
import DonutChart, { dataFormat as donutDataFormat } from '../charts/DonutChart';

type Props = {
  customerId: string;
  detailLink?: (...args: any[]) => any;
  classNames?: string;
};

const MonthlyCostsByResourceCard = ({ customerId, detailLink, classNames }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endDate = DateTime.local().minus({ month: 1 }).startOf('month').toJSDate();
        const startDate = DateTime.fromJSDate(endDate).minus({ month: 3 }).toJSDate();
        const res = await API.graphql(
          graphqlOperation(getCostsSummary, { params: { customerId }, query: { startDate } })
        );
        const data = (res as any)?.data?.getCostsSummary?.items ?? [];
        const costSummaryGraphData = data.map((cost: any) => {
          const month = new Date(`${cost.yearmonth}T00:00:00`);
          cost.month = {
            monthOrder: month.getTime(),
            monthName: month.toLocaleString('default', { month: 'long' }),
          };
          return cost;
        });
        const costSummaryGraphDataItems = costSummaryGraphData.filter(
          (summaryItem: any) => parseFloat(summaryItem.cost.toFixed(2)) > 0
        );
        const resources = [...new Set(costSummaryGraphData.map((costItem: any) => costItem.resource))].sort();
        const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        setChartOptions({
          // @ts-expect-error TS(2345): Argument of type '{ tooltip: { formatter: (value: ... Remove this comment to see the full error message
          tooltip: {
            formatter: donutDataFormat.money,
          },
          chart: {
            height: viewWidth >= 2560 ? 350 : 250,
          },
          series: !costSummaryGraphDataItems.length
            ? []
            : [
                {
                  name: 'Costs',
                  data: resources.map(item => ({
                    name: item,
                    y: costSummaryGraphData
                      .filter((costItem: any) => costItem.resource === item)
                      .map((costItem: any) => costItem.cost)
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      .reduce((a: any, b: any) => a + b),
                  })),
                },
              ],
          cSVFileName: 'Cost By Resource.csv',
          errorLoadingData: !!data.errors,
        });
        setLoading(false);
      } catch (err) {
        console.error('Error loading resource cost data: ', err);
        setError('Error Loading Data');
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return (
    <Card
      icon={<FontAwesomeIcon icon={faDollarSign} fixedWidth />}
      title="Cost By Resource"
      // @ts-expect-error TS(2322): Type '((...args: any[]) => any) | null' is not ass... Remove this comment to see the full error message
      viewDetailsCallback={detailLink || null}
      classNames={classNames}
      loading={loading}
      errorMessage={error}
      component={<DonutChart chartOptions={chartOptions} />}
    />
  );
};

export default MonthlyCostsByResourceCard;
