import React from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import noData from 'highcharts/modules/no-data-to-display';
import Boost from 'highcharts/modules/boost';

Exporting(Highcharts);
Boost(Highcharts);
noData(Highcharts);

export const dataFormat = {
  money: (value: any) =>
    value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
};

type Props = {
  chartOptions?: {
    title?: {
      text: string;
      style?: {
        fontSize?: string;
        margin?: number;
      };
      align?: string;
    };
    chart?: {
      type?: string;
      height?: number;
    };
    legend?: {
      align?: string;
      verticalAlign?: string;
      layout?: string;
      itemMarginTop?: number;
      itemMarginBottom?: number;
      x?: number;
    };
    plotOptions?: {
      pie?: {
        dataLabels?: {
          enabled?: boolean;
        };
      };
    };
    tooltip?: any;
    series?: {
      name: string;
      data: {
        y?: number;
        name: string;
        color?: string;
      }[];
    }[];
    cSVFileName: string;
    errorLoadingData?: boolean;
  };
};

const DonutChart = ({ chartOptions }: Props) => {
  // @ts-expect-error TS(2339): Property 'errorLoadingData' does not exist on type... Remove this comment to see the full error message
  const { errorLoadingData } = chartOptions;

  const options = {
    credits: {
      enabled: false,
    },
    title: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      text: chartOptions.title?.text,
      style: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        fontSize: chartOptions.title?.style?.fontSize ?? '16px',
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        margin: chartOptions.title?.style?.margin ?? 500,
      },
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      align: chartOptions.title?.align ?? 'center',
    },
    chart: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      type: chartOptions.chart?.type ?? 'pie',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      height: chartOptions.chart?.height ?? 250,
    },
    legend: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      align: chartOptions.legend ?? 'right',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      verticalAlign: chartOptions.legend?.verticalAlign ?? 'middle',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      layout: chartOptions.legend?.layout ?? 'vertical',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      itemMarginTop: chartOptions.legend?.itemMarginTop ?? 5,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      itemMarginBottom: chartOptions.legend?.itemMarginBottom ?? 5,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      x: chartOptions.legend?.x ?? 0,
    },
    exporting: {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      filename: chartOptions.cSVFileName.substring(0, chartOptions.cSVFileName.length - 4),
      buttons: {
        contextButton: {
          symbol: 'download',
          symbolFill: '#463490',
          symbolStroke: '#463490',
          menuItems: [
            'downloadJPEG',
            'downloadPDF',
            {
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              text: Highcharts.getOptions().lang.downloadCSV || 'Download CSV',
              onclick() {
                let csv = '';

                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                if (chartOptions.series.length > 0) {
                  const json: any = [];

                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  chartOptions.series[0].data.forEach(val => {
                    const value = {
                      Category: val.name,
                      Value: val.y,
                    };

                    json.push(value);
                  });
                  const fields = Object.keys(json[0]);
                  const replacer = function (key: any, value: any) {
                    return value === null ? '' : value;
                  };

                  // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
                  csv = json.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

                  (csv as any).unshift(fields.join(',')); // add header column
                  csv = (csv as any).join('\r\n');
                }

                const element = document.createElement('a');
                const file = new Blob([csv], { type: 'text/plain' });

                element.href = URL.createObjectURL(file);
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                element.download = chartOptions.cSVFileName;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              },
            },
          ],
        },
      },
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        boostThreshold: 1,
        dataLabels: {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          enabled: chartOptions.plotOptions?.pie?.dataLabels?.enabled ?? false,
        },
      },
    },
    tooltip: {
      // @ts-expect-error TS(7023): 'formatter' implicitly has return type 'any' becau... Remove this comment to see the full error message
      formatter() {
        // @ts-expect-error TS(2339): Property 'point' does not exist on type '{ formatt... Remove this comment to see the full error message
        const { point, key } = this;

        return `${key} ${point.series.name}: <b>${
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          chartOptions.tooltip?.formatter ? chartOptions.tooltip.formatter(point.y) : point.y
        }</b>`;
      },
    },
    series:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      chartOptions.series.length === 0
        ? []
        : [
            {
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              name: chartOptions.series[0].name,
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              data: chartOptions.series[0].data,
              innerSize: '60%',
              showInLegend: true,
            },
          ],
    lang: {
      noData: `${errorLoadingData ? 'Error Loading Data' : 'No Data Found'}`,
    },
    noData: {
      style: {
        fontFamily: 'Karla, sans-serif',
        fontWeight: 'normal',
        fontSize: '1rem',
        color: `${errorLoadingData ? '#D00505' : '#696279'}`,
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DonutChart;
