import React, { useState } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactHtmlParser from 'react-html-parser';
import Modal from './Modal';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import SupportTicketModal from './SupportTicketModal';
import { effectualGroups } from '../../shared/groupsHelper';
import { useUser } from '../../context/UserContext';

type Props = {
  doShow: boolean;
  cancel: (...args: any[]) => any;
  region?: string;
  instanceId?: string;
  vulnerability: any;
};

export default function VulnerabilityDetailsModal({ doShow, cancel, region, instanceId, vulnerability }: Props) {
  // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
  const { isEffectualUser, userInRoles } = useUser();
  const [showSupport, setShowSupport] = useState(false);

  const actionText = () => {
    if (!isEffectualUser) {
      return 'Need Assistance?';
    }

    if (userInRoles([effectualGroups.effectualObserver.groupName])) {
      return null;
    }

    if (region && instanceId) {
      return 'Go To Console';
    }

    return null;
  };

  const actionClick = () => {
    if (!isEffectualUser) {
      setShowSupport(true);
    } else if (region && instanceId) {
      window.open(
        `https://${region}.console.aws.amazon.com/ec2/v2/home?region=${region}#InstanceDetails:instanceId=${instanceId}`,
        '_blank'
      );
    }
  };

  return (
    <>
      <SupportTicketModal className="z-20" doShow={showSupport} cancel={() => setShowSupport(false)} />
      <Modal
        doShow={doShow}
        cancel={cancel}
        title={
          <span>
            Vulnerability <span className="text-orange">Details</span>
          </span>
        }
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        actionText={actionText()}
        action={actionClick}
        className="z-10"
      >
        {vulnerability && (
          <div>
            <div className="px-4 py-4">
              <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
                <h5 className="px-3 pb-5 text-b-800">{vulnerability.title}</h5>
                {vulnerability.vuln_type && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Vulnerability Type:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.vuln_type}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.severity_level && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Severity:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.severity_level}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.category && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Category:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.category}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.detection_info && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Detection Info:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.detection_info}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.last_customization && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Last Customization:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {DateTime.fromISO(vulnerability.last_customization).toLocaleString(DateTime.DATETIME_MED)}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.last_service_modification_datetime && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Last Service Modification:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {DateTime.fromISO(vulnerability.last_service_modification_datetime).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.published_datetime && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Published:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {DateTime.fromISO(vulnerability.published_datetime).toLocaleString(DateTime.DATETIME_MED)}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.bugtraq_list && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">Bugtraq:</dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {ReactHtmlParser(
                          vulnerability.bugtraq_list.reduce(
                            (previous: any, current: any) =>
                              `${previous ? `${previous}, ` : ''}<a href=${current.url}>${current.id}</a>`,
                            ''
                          )
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.patchable && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Patchable:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {Boolean(vulnerability.patchable).toString()}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.software_list && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Software:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.software_list.reduce(
                          (previous: any, current: any) =>
                            `${previous ? `${previous}, ` : ''}${current.product} (${current.vendor})`,
                          ''
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.vendor_reference_list && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Vendor References:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {ReactHtmlParser(
                          vulnerability.vendor_reference_list.reduce(
                            (previous: any, current: any) =>
                              `${previous ? `${previous}, ` : ''}<a href=${current.url}>${current.id}</a>`,
                            ''
                          )
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.cve_list && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">CVE:</dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {ReactHtmlParser(
                          vulnerability.cve_list.reduce(
                            (previous: any, current: any) =>
                              `${previous ? `${previous}, ` : ''}<a href=${current.url}>${current.id}</a>`,
                            ''
                          )
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.compliance_list && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Compliances:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {ReactHtmlParser(
                          vulnerability.compliance_list.reduce(
                            (previous: any, current: any) =>
                              `${previous ? `${previous}, ` : ''}${current.type} - ${current.section} - ${
                                current.description
                              }</br>`,
                            ''
                          )
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.correlation_exploits && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Correlation Exploits:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {ReactHtmlParser(
                          vulnerability.correlation_exploits
                            .map(
                              (x: any) =>
                                `${x.src_name} - (${x.explt_list
                                  .map((e: any) => `<a href="${e.link}" title="${e.desc}">${e.ref}</a>`)
                                  .join(', ')})`
                            )
                            .join(', ')
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.correlation_malware && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Correlation Malware:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {ReactHtmlParser(
                          vulnerability.correlation_malware
                            .map(
                              (x: any) =>
                                `${x.src_name} - (${x.mw_list
                                  .map(
                                    (e: any) =>
                                      `<a href="${e.mw_link}" title="${e.mw_type} - ${e.mw_platform} - ${e.mw_alias} - ${e.mw_rafting}">${e.mw_id}</a>`
                                  )
                                  .join(', ')})`
                            )
                            .join(', ')
                        )}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.pci_flag && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">PCI:</dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {Boolean(vulnerability.pci_flag).toString()}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.automatic_pci_fail && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Automatic PCI Fail:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {Boolean(vulnerability.automatic_pci_fail).toString()}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.pci_reasons && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        PCI Reasons:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.pci_reasons.join(', ')}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.threat_intelligence && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Threat Intelligence:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.threat_intelligence.map((x: any) => x.content).join(', ')}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.supported_modules && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Supported Modules:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.supported_modules}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.discovery && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Discovery:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.discovery.additional_info ? `${vulnerability.discovery.additional_info} ` : ''}
                        {vulnerability.discovery.auth_type_list
                          ? `(${vulnerability.discovery.auth_type_list.join(', ')})`
                          : ''}
                        {vulnerability.discovery.remote
                          ? `Remote: ${Boolean(vulnerability.discovery.remote).toString()}`
                          : ''}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.is_disabled && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Disabled:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {Boolean(vulnerability.is_disabled).toString()}
                      </dd>
                    </div>
                  </dl>
                )}
                {vulnerability.change_log_list && (
                  <dl className="pb-1">
                    <div className="px-3 py-1 bg-gray-100 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                      <dt className="text-sm font-medium leading-5 text-b-800 sm:col-span-3 md:col-span-1">
                        Change Log:
                      </dt>
                      <dd className="text-sm leading-5 text-b-800 sm:mt-0 sm:col-span-3 md:col-span-5">
                        {vulnerability.change_log_list
                          .map(
                            (x: any) =>
                              `${x.comments} (${DateTime.fromISO(x.change_date).toLocaleString(DateTime.DATETIME_MED)})`
                          )
                          .join(', ')}
                      </dd>
                    </div>
                  </dl>
                )}
              </div>
            </div>
            <div className="px-4 py-1">
              <div className="mt-3 text-center bg-gray-100 sm:mt-0 sm:mx-4 sm:text-left">
                <p className="px-6 py-2 text-base font-bold text-purple-700">Threat Description:</p>
                <div className="px-6 pb-3 text-sm leading-4 text-gray-600">
                  {ReactHtmlParser(vulnerability.diagnosis)}
                </div>
                {vulnerability.diagnosis_comment && (
                  <div className="px-6 pb-3 text-sm leading-4 text-gray-600">
                    {ReactHtmlParser(vulnerability.diagnosis_comment)}
                  </div>
                )}
              </div>
            </div>
            <div className="px-4 py-1">
              <div className="mt-3 text-center bg-gray-100 sm:mt-0 sm:mx-4 sm:text-left">
                <p className="px-6 py-2 text-base font-bold text-purple-700">Impact:</p>
                <div className="px-6 pb-3 text-sm leading-4 text-gray-600">
                  {ReactHtmlParser(vulnerability.consequence)}
                </div>
                {vulnerability.consequence_comment && (
                  <div className="px-6 pb-3 text-sm leading-4 text-gray-600">
                    {ReactHtmlParser(vulnerability.consequence_comment)}
                  </div>
                )}
              </div>
            </div>
            <div className="px-4 py-1">
              <div className="mt-3 text-center bg-gray-100 sm:mt-0 sm:mx-4 sm:text-left">
                <p className="px-6 py-2 text-base font-bold text-purple-700">Solution:</p>
                <div className="px-6 pb-3 text-sm leading-4 text-gray-600">
                  {ReactHtmlParser(vulnerability.solution)}
                </div>
                {vulnerability.solution_comment && (
                  <div className="px-6 pb-3 text-sm leading-4 text-gray-600">
                    {ReactHtmlParser(vulnerability.solution_comment)}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
